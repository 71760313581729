var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('centered-column-layout',[_c('page-title',{attrs:{"custom-help-message":_vm.GreetingPageHelpMessage},scopedSlots:_vm._u([{key:"custom-button",fn:function(){return [_c('a-button',{staticClass:"btn-success",on:{"click":function($event){return _vm.$store.dispatch('saveChatConfig')}}},[_vm._v(" "+_vm._s(_vm.$t('chat_config_save_button'))+" ")])]},proxy:true}])}),_c('a-card',{attrs:{"title":_vm.$t('field_remove_system_message_title')}},[_c('a-alert',{staticClass:"mx-2",attrs:{"description":_vm.$t('field_remove_system_message_alert').toString()}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': { 'model': _vm.stateChatConfigPath, 'key': 'hello_remove',
                  'hasAnchor': true, },
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': { 'model': _vm.stateChatConfigPath, 'key': 'leave_remove',
                  'hasAnchor': true, }
      }}})],1),_c('a-card',{staticClass:"mt-5",attrs:{"title":_vm.$t('field_enter_user_to_chat_title')}},[_c('a-alert',{staticClass:"mx-2",attrs:{"description":_vm.$t('field_enter_user_to_chat_description').toString()}}),_c('validation-observer',{attrs:{"slim":""}},[_c('switch-input',{attrs:{"setup":{
          'func': _vm.configInputSetup,
          'args': {
            'model': _vm.stateChatConfigPath,
            'key': 'silent_invite',
            'tariffTags': _vm.getTagsByFieldKey('field_silent_invite'),
            'hasAnchor': true,
          }
        }}}),_c('transition',{attrs:{"name":"fade"}},[(!_vm.$store.state.chatState.chat.config.silent_invite)?_c('nested-content',[_c('switch-input',{attrs:{"setup":{
              'func': _vm.configInputSetup,
              'args': {
                'model': _vm.stateChatConfigPath,
                'key': 'silent_invite_by_admins',
                'tariffTags': _vm.getTagsByFieldKey('field_silent_invite'),
                'hasAnchor': true,
              }
            }}})],1):_vm._e()],1),_c('a-divider'),_c('multi-message-editor-with-media-input',{attrs:{"setup":{
          'func': _vm.configInputSetup,
          'args': {
            'model': _vm.stateChatConfigPath,
            'key': 'hello_message',
            'placeholders': _vm.GREETING_PLACEHOLDERS,
            'targetToUpload': function () { return _vm.groupUploadTarget; },
            'availableButtonsTypes': _vm.defaultMessageEditorButtons,
            'editorMode': _vm.MediaEditorMode.TelegramShort,
            'hasMedia': _vm.$store.getters.getHaveLicense && _vm.$store.getters.getHaveBrand,
            'base-api-url': 'api.chatkeeper.app/cabinet',
            'options': {
              remove_after: _vm.checkLicense(),
              send_after: _vm.checkLicense()
            },
            'customHelpMessage': _vm.GreetingHelloMessageHelpView,
            'hasAnchor': true,
          },
        }}}),_c('file-urls-list-input',{attrs:{"setup":{
          'func': _vm.configInputSetup,
          'args': { 'model': _vm.stateChatConfigPath, 'key': 'enter_message_images_list',
                    'hasAnchor': true, }
        }}}),_c('switch-input',{ref:"remove_previos_hellow",attrs:{"setup":{
          'func': _vm.configInputSetup,
          'args': {
            'model': _vm.stateChatConfigPath, 'key': 'remove_previos_hellow',
            'hasAnchor': true,
          },
        }}}),_c('a-divider'),_c('select-input',{attrs:{"setup":{
          'func': _vm.configInputSetup,
          'args': {
            'model': this,
            'key': 'enterProtectionTypeValue',
            'options': _vm.getProtectionTypes,
            'clearable': false,
            'validation': 'depends_on_multi_editor_required_text:@hello_message,depends_on_hello_message_required_text',
            'customHelpMessage': _vm.GreetingEnterProtectionTypeValueHelpView,
            'hasAnchor': true,
          }
        }}}),(_vm.enterProtectionTypeValue !== 'FormWithoutValidation')?_c('mini-help-message',{staticClass:"px-2",attrs:{"message":_vm.$t(("greeting_check_new_user_" + (_vm.snakeCase(_vm.enterProtectionTypeValue)) + "_help_message"))}}):_vm._e(),_c('transition',{attrs:{"name":"fade"}},[(_vm.$store.state.chatState.chat.config.check_new_users &&
            _vm.$store.state.chatState.chat.config.enter_protection_type !== 'None')?_c('nested-content',[_c('a-card',{staticClass:"shadow-none bg-transparent mt-0 mb-0 pt-0 pb-0 relative"},[(_vm.isAvailableProtectionType(_vm.protectionType))?[(_vm.protectionType === 'BindedChannel')?_c('channel-binding',{staticClass:"m-2 mb-5",attrs:{"pre-alert":_vm.$t('bind_channel_pre_alert_greeting'),"post-alert":_vm.getBingChannelInfo()}}):_vm._e(),(_vm.protectionType === 'VariableCaptcha')?_c('variable-capcha'):_vm._e(),((_vm.protectionType === 'FormWithValidation' || _vm.protectionType === 'FormWithoutValidation'))?_c('protection-type-form'):_vm._e(),(_vm.protectionType === 'InviteValidation')?_c('invite-validation'):_vm._e(),(_vm.enterProtectionTypeValue !== 'MathCaptcha' && _vm.enterProtectionTypeValue !== 'VariableCaptcha')?_c('text-input',{attrs:{"setup":{
                  'func': _vm.configInputSetup,
                  'args': {
                    'model': _vm.stateChatConfigPath,
                    'key': 'check_new_users_text',
                    'hasAnchor': true,
                  }
                }}}):_vm._e(),(_vm.enterProtectionTypeValue !== 'FormWithValidation' || _vm.enterProtectionTypeValue !== 'FormWithoutValidation')?_c('switch-input',{attrs:{"setup":{
                  'func': _vm.configInputSetup,
                  'args': {
                    'model': _vm.stateChatConfigPath,
                    'key': 'remove_hellow_after_button_pressed',
                    'prefix': _vm.enterProtectionTypeValue === 'FormWithValidation' || _vm.enterProtectionTypeValue === 'FormWithoutValidation' ? 'form_' : '',
                    'disabled': !_vm.$store.getters.isChatLicenseExists,
                    'tariffTags': _vm.getTagsByFieldKey('field_remove_hellow_after_button_pressed'),
                    'hasAnchor': true,
                  }
                }}}):_vm._e(),(!_vm.$store.state.chatState.chat.config.remove_hellow_after_button_pressed)?_c('switch-input',{attrs:{"setup":{
                  'func': _vm.configInputSetup,
                  'args': {
                    'model': _vm.stateChatConfigPath,
                    'key': 'remove_hellow_button',
                    'hasAnchor': true,
                  }
                }}}):_vm._e(),_c('time-unit-input',{attrs:{"setup":{
                  'func': _vm.configInputSetup,
                  'args': {
                    'model': _vm.stateChatConfigPath,
                    'key': 'remove_not_confirm_users',
                    'step': _vm.StepUnit.Minute,
                    'hasAnchor': true,
                  }
                }}}),_c('time-unit-input',{attrs:{"setup":{
                  'func': _vm.configInputSetup,
                  'args': {
                    'model': _vm.stateChatConfigPath,
                    'key': 'ban_unverified_users_time',
                    'tariffTags': _vm.getTagsByFieldKey('ban_unverified_users_time'),
                    'hasAnchor': true,
                  }
                }}}),_c('select-input',{attrs:{"setup":{
                  'func': _vm.configInputSetup,
                  'args': {
                    'model': _vm.stateChatConfigPath,
                    'key': 'after_greeting_check_trigger',
                    'tariffTags': _vm.getTagsByFieldKey('after_greeting_triggers'),
                    'hasAnchor': true,
                  }
                }}}),(_vm.$store.state.chatState.chat.config.enter_protection_type !== 'InviteValidation')?_c('switch-input',{attrs:{"setup":{
                  'func': _vm.configInputSetup,
                  'args': {
                    'model': _vm.stateChatConfigPath,
                    'key': 'allow_invite',
                    'hasAnchor': true,
                  }
                }}}):_vm._e()]:_c('not-available-options-overlay',{staticClass:"bg-transparent relative",attrs:{"tags":_vm.getProtectionTypeTags(_vm.protectionType)}})],2)],1):_vm._e()],1),_c('a-divider'),(_vm.isTriggerSet('after_greeting_trigger'))?_c('div',{staticClass:"relative"},[_c('select-input',{attrs:{"setup":{
            'func': _vm.configInputSetup,
            'args': {
              'model': _vm.stateChatConfigPath,
              'key': 'after_greeting_trigger',
              'tariffTags': _vm.getTagsByFieldKey('after_greeting_triggers'),
              'hasAnchor': true,
            }
          }}}),_c('sup',{staticClass:"absolute",staticStyle:{"left":"5px","top":"-12px"}},[_c('tags',{attrs:{"tags":[{ text: _vm.$t('field_will_be_removed_soon'), color: 'rgba(var(--a-danger),1)' }]}})],1),_c('select-input',{attrs:{"setup":{
            'func': _vm.configInputSetup,
            'args': {
              'model': _vm.stateChatConfigPath,
              'key': 'after_greeting_trigger_new',
              'tariffTags': _vm.getTagsByFieldKey('after_greeting_triggers'),
              'translationParams': { channel: _vm.$route.params[_vm.EntityTypes.CHAT_ID] },
              'hasAnchor': true,
            }
          }}})],1):_c('select-input',{attrs:{"setup":{
          'func': _vm.configInputSetup,
          'args': {
            'model': _vm.stateChatConfigPath,
            'key': 'after_greeting_trigger_new',
            'tariffTags': _vm.getTagsByFieldKey('after_greeting_triggers'),
            'translationParams': { channel: _vm.$route.params[_vm.EntityTypes.CHAT_ID] },
            'hasAnchor': true,
          }
        }}}),(_vm.isTriggerSet('after_first_greeting_trigger'))?_c('div',{staticClass:"relative"},[_c('select-input',{attrs:{"setup":{
            'func': _vm.configInputSetup,
            'args': {
              'model': _vm.stateChatConfigPath,
              'key': 'after_first_greeting_trigger',
              'tariffTags': _vm.getTagsByFieldKey('after_greeting_triggers'),
              'translationParams': { channel: _vm.$route.params[_vm.EntityTypes.CHAT_ID] },
              'hasAnchor': true,
            }
          }}}),_c('sup',{staticClass:"absolute",staticStyle:{"left":"5px","top":"-12px"}},[_c('tags',{attrs:{"tags":[{ text: _vm.$t('field_will_be_removed_soon'), color: 'rgba(var(--a-danger),1)' }]}})],1),_c('select-input',{attrs:{"setup":{
            'func': _vm.configInputSetup,
            'args': {
              'model': _vm.stateChatConfigPath,
              'key': 'after_first_greeting_trigger_new',
              'tariffTags': _vm.getTagsByFieldKey('after_greeting_triggers'),
              'taggable': true,
              'translationParams': { channel: _vm.$route.params[_vm.EntityTypes.CHAT_ID] },
              'hasAnchor': true,
            }
          }}})],1):_c('select-input',{attrs:{"setup":{
          'func': _vm.configInputSetup,
          'args': {
            'model': _vm.stateChatConfigPath,
            'key': 'after_first_greeting_trigger_new',
            'tariffTags': _vm.getTagsByFieldKey('after_greeting_triggers'),
            'translationParams': { channel: _vm.$route.params[_vm.EntityTypes.CHAT_ID] },
            'hasAnchor': true,
          }
        }}}),(_vm.isTriggerSet('after_not_first_greeting_trigger'))?_c('div',{staticClass:"relative"},[_c('select-input',{attrs:{"setup":{
            'func': _vm.configInputSetup,
            'args': {
              'model': _vm.stateChatConfigPath,
              'key': 'after_not_first_greeting_trigger',
              'tariffTags': _vm.getTagsByFieldKey('after_greeting_triggers'),
              'hasAnchor': true,
            }
          }}}),_c('sup',{staticClass:"absolute",staticStyle:{"left":"5px","top":"-12px"}},[_c('tags',{attrs:{"tags":[{ text: _vm.$t('field_will_be_removed_soon'), color: 'rgba(var(--a-danger),1)' }]}})],1),_c('select-input',{attrs:{"setup":{
            'func': _vm.configInputSetup,
            'args': {
              'model': _vm.stateChatConfigPath,
              'key': 'after_not_first_greeting_trigger_new',
              'tariffTags': _vm.getTagsByFieldKey('after_greeting_triggers'),
              'hasAnchor': true,
            }
          }}})],1):_c('select-input',{attrs:{"setup":{
          'func': _vm.configInputSetup,
          'args': {
            'model': _vm.stateChatConfigPath,
            'key': 'after_not_first_greeting_trigger_new',
            'tariffTags': _vm.getTagsByFieldKey('after_greeting_triggers'),
            'translationParams': { channel: _vm.$route.params[_vm.EntityTypes.CHAT_ID] },
            'hasAnchor': true,
          }
        }}})],1)],1),_c('a-card',{staticClass:"mt-5",attrs:{"title":_vm.$t('field_leave_user_from_chat_title')}},[_c('a-alert',{attrs:{"description":_vm.$t('field_leave_user_from_chat_alert').toString()}}),_c('multi-message-editor-with-media-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.stateChatConfigPath,
          'key': 'leave_member_message',
          'placeholders': _vm.GREETING_PLACEHOLDERS,
          'targetToUpload': function () { return _vm.groupUploadTarget; },
          'availableButtonsTypes': _vm.defaultMessageEditorButtons,
          'editorMode': _vm.MediaEditorMode.TelegramShort,
          'hasMedia': _vm.$store.getters.getHaveLicense && _vm.$store.getters.getHaveBrand,
          'base-api-url': 'api.chatkeeper.app/cabinet',
          'options': {
            remove_after: _vm.checkLicense(),
            send_after: _vm.checkLicense()
          },
          'customHelpMessage': _vm.GreetingLeaveMemberMessageHelpView,
          'hasAnchor': true,
        },
      }}}),_c('select-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.stateChatConfigPath,
          'key': 'after_leave_trigger',
          'tariffTags': _vm.getTagsByFieldKey('after_greeting_triggers'),
          'translationParams': { channel: _vm.$route.params[_vm.EntityTypes.CHAT_ID] },
          'hasAnchor': true,
        }
      }}})],1),_c('a-card',{staticClass:"mt-5",attrs:{"title":_vm.$t('field_chat_buttons_block_title')}},[_c('a-alert',{staticClass:"mx-2",attrs:{"description":_vm.$t('field_chat_buttons_block_description').toString()}}),_c('multi-message-editor-with-media-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.stateChatConfigPath,
          'key': 'chat_buttons_message',
          'placeholders': _vm.GREETING_PLACEHOLDERS,
          'targetToUpload': function () { return _vm.groupUploadTarget; },
          'availableButtonsTypes': _vm.defaultMessageEditorButtons,
          'editorMode': _vm.MediaEditorMode.TelegramShort,
          'hasMedia': _vm.$store.getters.getHaveLicense && _vm.$store.getters.getHaveBrand,
          'base-api-url': 'api.chatkeeper.app/cabinet',
          'options': {
            remove_after: _vm.checkLicense(),
            send_after: _vm.checkLicense()
          },
          'customHelpMessage': _vm.GreetingChatButtonsMessageHelpView,
          'hasAnchor': true,
        },
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.stateChatConfigPath,
          'key': 'chat_buttons_one_time',
          'translationParams': { channel: _vm.$route.params[_vm.EntityTypes.CHAT_ID] },
          'hasAnchor': true,
        }
      }}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }